<template>
    <v-container class="fill-height" fluid>
        <v-flex style="padding-top: 90px">
            <v-layout row justify-center>
                <v-flex xs12 sm8 md6 lg4 xl3  class="pa-10">
                    <v-card class="elevation-4 pa-8 pt-0 mx-auto">
                        <v-form @submit.prevent="login">
                            <v-card-text>
                                <v-card width="150" height="150" class="pa-8 mx-auto round-card" color="#FEC74E">
                                    <v-layout justify-center>
                                        <v-img src="../assets/logo.png" max-width="100" max-height="100">

                                        </v-img>
                                    </v-layout>
                                </v-card>
                                <v-flex>
                                    <div class="headline text-center pa-4 font-weight-black primary--text">MASUK</div>
                                    <v-alert type="error" v-if="error&&message">
                                        {{message}}
                                    </v-alert>
                                    <v-text-field
                                    label="Username"
                                    name="username"
                                    prepend-icon="mdi-account"
                                    type="text"
                                    v-model="user.username"
                                    ></v-text-field>

                                    <v-text-field
                                    id="password"
                                    label="Password"
                                    name="password"
                                    prepend-icon="mdi-lock"                                    
                                    :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="show ? 'text' : 'password'"
                                    @click:append="show = !show"
                                    v-model="user.password"
                                    ></v-text-field>
                                    <v-layout justify-end wrap>
                                        <v-btn text x-small class="text-none font-weight-bold text-center" color="primary" to="forgot-password">Forgot Password?</v-btn>
                                    </v-layout>
                                </v-flex>
                            </v-card-text>
                            <v-card-actions>
                                <!-- <v-spacer></v-spacer> -->
                                <v-layout justify-center wrap>
                                    <v-btn type="submit" block rounded color="primary" class="font-weight-bold" :disabled="loading" :loading="loading" x-large>Masuk</v-btn>
                                    <div class="ma-7 mb-0 light-grey--text caption text-center">Tidak mempunyai akun?<v-btn text x-small class="text-none font-weight-bold text-center" color="primary" to="/register">Daftar disini</v-btn></div>
                                </v-layout>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                    <v-layout row class="lighter-grey--text caption font-weight-thin text-center mt-10" justify-center>Copyright {{ new Date().getFullYear() }} © Grafindo Media Pratama. All Right Reserved.</v-layout>
                </v-flex>
            </v-layout>
        </v-flex>
    </v-container>
</template>

<script>
  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
      loading: false,
      user: {},
      message: '',
      error: false,
      show: false
    }),
    methods: {
        login(){
            this.loading=true
            this.$store.dispatch('login', this.user)
            .then(() => {
                if(this.$store.getters.isLoggedIn) this.$router.push({name:'home'})
                this.message = this.$store.state.message
                this.error = true
                this.loading=false
            })
            .catch(() => {
                this.message = this.$store.state.message
                this.error = true
                this.loading=false
            })
            // setTimeout(() => {
            //     this.$router.push({name:'home',params: { login: true }});    
            // }, 1000);
        }
    }
  }
</script>

<style>
    .round-card{
        border-radius: 100px !important;
        margin-top: -90px;
    }
</style>