<template>
    <v-container class="fill-height" fluid>
        <v-flex :class="{'py-2': $vuetify.breakpoint.smAndDown}">
        <v-layout row justify-center>
        <v-flex xs10 md8 xl7 v-if="!loading">
            <v-layout row>
                <v-btn class="mb-3 text-none" depressed color="grey lighten-2" @click="ulangi"><v-icon>mdi-chevron-left</v-icon>Ulangi</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="mb-3 text-none" depressed color="grey lighten-2" to="/">Home<v-icon>mdi-chevron-right</v-icon></v-btn>
                <v-flex xs12>
                <v-layout row justify-center>
                    <!-- <v-flex xs12 lg6 class="pa-3">
                        <v-card :loading="loading">
                            <v-toolbar
                                color="primary"
                                dark
                                flat
                                class="px-5"
                            >
                                <v-toolbar-title>Rekap Jawaban</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-container class="fill-height">
                                    <v-flex>
                                        <v-layout row wrap justify-center v-if="jawaban[0]">
                                            <v-chip :class="{'red red--text ma-2': !soal.status||soal.jawab==null, 'green green--text ma-2': soal.status}" outlined label v-for="(soal, n) in mapel.soal" :key="soal.id">
                                                <v-sheet width="20" :class="{'red text-center mr-2 white--text': !soal.status||soal.jawab==null, 'green text-center mr-2 white--text': soal.status}" style="border-radius: 3px">
                                                    {{ n+1 }}
                                                </v-sheet>
                                                <div class="answer-field">{{soal.jawab}}</div>
                                                <v-icon dark small class="ml-2" v-if="!soal.status">mdi-close-circle</v-icon>
                                                <v-icon dark small class="ml-2" v-else>mdi-check-circle</v-icon>
                                            </v-chip>
                                        </v-layout>
                                    </v-flex>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-flex> -->
                    <v-flex xs12 lg6 class="pa-3">
                        <v-card :loading="loading">
                            <v-toolbar
                                color="primary"
                                dark
                                flat
                                class="px-5"
                            >
                                <v-toolbar-title>Lembar Skor</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-card outlined tile>
                                    <v-card-title class="font-weight-bold">TOTAL SKOR</v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text class="py-8 display-1 font-weight-bold text-center">
                                        {{skor}}                                   
                                    </v-card-text>
                                </v-card>
                                <v-card outlined tile class="my-4">
                                    <v-card-title class="font-weight-bold">INDIKATOR SKOR</v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text class="py-8 title font-weight-bold text-center">
                                        {{indikator}}
                                    </v-card-text>
                                </v-card>
                                <v-card outlined tile class="d-flex flex-column align-center">
                                    <v-img :src="emoticon" v-if="emoticon" width="100px" class="mt-8">
                                        <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                        </v-row>
                                        </template>
                                    </v-img>
                                    <v-card-text class="py-8 title font-weight-bold text-center" v-html="saran">
                                    </v-card-text>
                                </v-card>
                                <!-- <v-card v-for="coba in cobas" :key="coba.id" class="my-2">
                                    <v-card-title>
                                        {{coba.nama}}
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="toggleActive(coba)">
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text v-show="coba.active">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium cupiditate nam quaerat aliquid doloremque neque autem, eum tempore tenetur ab id quo molestiae qui laborum facere, tempora ipsa eveniet dolore?
                                    </v-card-text>
                                </v-card> -->
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
                </v-flex>
            </v-layout>
            <v-layout row class="lighter-grey--text caption font-weight-thin mt-5 text-center" justify-center>Copyright {{ new Date().getFullYear() }} © Grafindo Media Pratama. All Right Reserved.</v-layout>
        </v-flex>
        <v-dialog
            v-model="loading"
            persistent
            width="300"
            >
            <v-card
                color="accent"
                dark
            >
                <v-card-text class="pt-5">
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" max-width="290">
                <v-card>
                    <v-card-title class="headline">Success</v-card-title>
                    <v-card-text>{{ message }}</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog=false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogOffline" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Offline</v-card-title>
                    <v-card-text>Tidak ada internet, silahkan coba kembali</v-card-text>
                    <v-card-actions>
                    <!-- <v-btn color="green darken-1" text :to="'/mapel/'+mapel_id+'/paket'">Kembali ke soal</v-btn> -->
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="get">Coba lagi</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
        </v-flex>
    </v-container>
</template>

<script>
// import axios from 'axios'
import * as api from '@/api'
import store from '../store/store'
  export default {
    data(){
        return {
            loading: false,
            cobas: [
                {
                    id: 1,
                    nama: 'Coba',
                },
                {
                    id: 2,
                    nama: 'Nyobian'
                },
                {
                    id:3,
                    nama: 'Mencoba'
                }
            ],
            // mapel: JSON.parse(localStorage.getItem('mapel')),
            mapelNew: {},
            jawaban: {},
            kategori: {},
            indikator: '',
            saran: '',
            skor: 0,
            emoticon: null,
            dialog: false,
            message: '',
            dialogOffline: false
        }
    },
    methods:{
        ulangi(){
            var id_mapel, id_paket
            id_mapel = this.mapel.id
            id_paket = this.mapel.id_paket
            store.commit('removeMapel')
            this.$router.push('/paket/'+id_mapel+'/soal/'+id_paket)
        },
        toggleActive(coba){
            this.$set(coba, 'active', !coba.active)
        },
        async get(){
            this.loading = true
            this.dialogOffline = false
            if(navigator.onLine){
                var options = { year: 'numeric', month: 'long', day: 'numeric' }
                var date = new Date()
                this.mapel.soal.forEach(element => {
                    this.$set(element, 'soal', null)
                    this.$set(element, 'audio', null)
                    this.$set(element, 'pilihan_jawaban', null)
                });
                this.mapel.tanggal = date.toLocaleDateString("id-ID", options)
                await api.post('/penilaian', this.mapel, async (res) => {
                    if (res.status) {
                        this.skor = res.data.skor
                        var kat = res.data.kategori
                        this.indikator = kat.kriteria
                        this.saran = kat.saran
                        this.emoticon = kat.emoticon
                        this.loading = false
                        if(res.data.status){
                            this.dialog = true
                            this.message = res.data.message
                            // store.commit('removeMapel')
                        }
                    }
                })
            }else{
                this.loading = false
                this.dialogOffline = true
            }
        }
    },
    watch: {
        
    },
    mounted(){
        // this.mapel = JSON.parse(localStorage.getItem('mapel'))
        this.get()
    },
    computed: {
        mapel(){
            return this.$store.state.mapel
        }
    }
  }
</script>

<style scoped>
    .answer-field{
        min-width: 9.06px;
    }
    .left-side{
        min-width: 173px;
    }
</style>