import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    content_copy: 'content-copy'
  },
  theme:{
    themes: {
      light: {
        primary: '#6388FA',
        "lighter-grey": '#C4C4C4',
        "light-grey": '#A0AEC0',
        "dark-grey": '#5A5A5A',
        background: '#F5F5F5',
        accent: "#FEC74E",
      }
    }
  }
});
