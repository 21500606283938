<template>
  <v-app style="background: #F5F5F5;">
    <v-app-bar app class="accent" v-if="!noToolbar" elevation="0">
      <v-toolbar-title class="headline text-uppercase pl-4">
        <v-img src="./assets/Logo 1.png" max-height="50" max-width="180" v-show="!noLogo"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          fab
          depressed
          dark
          v-on="on"
          small
        >
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content v-if="nama_lengkap">
              <v-list-item-title>{{nama_lengkap}}</v-list-item-title>
              <v-list-item-subtitle>Siswa</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item v-ripple style="cursor: pointer" @click="openDialog()">
              <v-list-item-content>
                <v-list-item-title>Ubah Profile</v-list-item-title>
              </v-list-item-content>           
          </v-list-item>
          <v-list-item v-ripple style="cursor: pointer" @click="openDialogPass()">
              <v-list-item-content>
                <v-list-item-title>Ubah Password</v-list-item-title>
              </v-list-item-content>           
          </v-list-item>
          <v-list-item v-ripple style="cursor: pointer" @click="logout">
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>           
          </v-list-item>
        </v-list>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="menu = false">Cancel</v-btn>
          <v-btn color="primary" text @click="menu = false">Save</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-menu>
      <!-- <v-btn text @click="logout" class="ml-4">
        <span class="mr-2 white--text font-weight-bold text-none">Logout</span><v-icon color="white">mdi-exit-to-app</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-content>
      <router-view/>
    </v-content>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">User Profile</span>
        </v-card-title>
        <v-form ref="form" @submit.prevent="updateProfile">
          <v-card-text>
            <v-container>
              <v-row>
                <!-- <v-col cols=12>
                  <v-row justify="center">
                    <v-avatar
                      class="profile"
                      color="grey"
                      size="164"
                    >
                      <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg"></v-img>
                    </v-avatar>
                  </v-row>
                </v-col> -->
                <v-col cols="12" v-if="error&&message">
                  <v-alert type="error" transition="fade-transition">
                      {{message}}
                  </v-alert>
                </v-col>
                <v-col cols="12" v-if="success&&message" transition="fade-transition">
                  <v-alert type="success">
                      {{message}}
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Username" readonly :rules="requiredRules" required v-model="user.username"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Nama Lengkap" readonly :rules="requiredRules" required v-model="user.nama_lengkap"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Kelas" readonly :rules="requiredRules" required v-model="user.kelas"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Nama Sekolah" :rules="requiredRules" v-model="user.sekolah"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Kota / Kab"
                    required v-model="user.kota" :rules="requiredRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="Alamat Email" :rules="[emailRules]" required v-model="user.email"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="No. HP" v-model="user.no_hp"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="user.nama_guru" :rules="requiredRules" label="Nama Guru" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="user.email_guru" :rules="requiredEmailRules" label="Alamat Email Guru"></v-text-field>
                </v-col>
                
                <!-- <v-col cols="12">
                  <v-text-field label="Password*" type="password" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="['0-17', '18-29', '30-54', '54+']"
                    label="Age*"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                    label="Interests"
                    multiple
                  ></v-autocomplete>
                </v-col> -->
              </v-row>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Batal</v-btn>
            <v-btn color="blue darken-1" text type="submit">Simpan</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_pass" max-width="600px">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Ubah Password</span>
        </v-card-title>
        <v-form ref="formpass" @submit.prevent="updatePassword">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" v-if="error&&message">
                  <v-alert type="error">
                      {{message}}
                  </v-alert>
                </v-col>
                <v-col cols="12" v-if="success&&message">
                  <v-alert type="success">
                      {{message}}
                  </v-alert>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                    id="passwordlama"
                    label="Password Lama"
                    name="password"                                    
                    :rules="requiredRules"                           
                    :append-icon="show3 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="show3 ? 'text' : 'password'"
                    @click:append="show3 = !show3"
                    v-model="password.current_password"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                    id="password"
                    label="Password Baru"
                    name="password"                                    
                    :rules="requiredRules"                           
                    :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    v-model="password.password"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                    id="confirmPassword"
                    label="Confirm Password Baru"
                    name="confirm_password"     
                    :rules="passwordRules"                           
                    :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    v-model="password.confirm_password"
                    ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog_pass = false">Batal</v-btn>
            <v-btn color="blue darken-1" text type="submit">Simpan</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- <v-navigation-drawer
      v-model="right"
      fixed
      right
      temporary
    ></v-navigation-drawer> -->
  </v-app>
</template>

<script>
import * as api from '@/api'
import store from './store/store'
export default {
  name: 'App',
  components: {

  },
  data(){
    return {
      noToolbar: false,
      noLogo: false,
      loading: false,
      login: false,
      menu: false,
      dialog: false,
      dialog_pass: false,
      user: {email: ''},
      // nama_lengkap: localStorage.getItem('nama_lengkap'),
      requiredRules: [
        v => !!v || 'The input is required',
      ],
      // emailRules: [
      //   v => /.+@.+/.test(v) || "E-mail must be valid",
      // ],
      requiredEmailRules: [
          v => !!v || 'The input is required',
          v => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      success: false,
      error: false,
      message: '',
      password: {},
      show1: false,
      show2: false,
      show3: false,
    }
  },
  mounted(){
    let route = this.$route
    this.noToolbar = route.meta.noToolbar
    this.noLogo = route.meta.noLogo
  },
  watch: {
      '$route' (to) {
        this.noToolbar = to.meta.noToolbar
        this.noLogo = to.meta.noLogo
      },
      userNew (newUser) {
        // Our fancy notification (2).
        this.nama_lengkap = newUser
      }
    },
  computed: {
    userNew () {
      return this.$store.state.user.nama_lengkap
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    },
    passwordRules() {
        return [
            () => (this.password.password === this.password.confirm_password) || 'Password must match',
            v => !!v || 'The Input is required'
        ];
    },
    nama_lengkap(){
      return store.state.user.nama_lengkap
    }
  },
  methods:{
    logout(){
      this.menu = false
      this.$store.dispatch('logout')
    },
    async openDialog() {
      this.success = false              
      this.error = false
      this.dialog=true
      this.loading = true
      await api.get('/auth/get-profile', {}, async (res) => {
          if (res.status) {
              this.user = res.data.user
              // setTimeout(()=>{
                  this.loading = false
              // }, 100)
          }
      })
    },
    openDialogPass() {
      this.success = false              
      this.error = false
      this.dialog_pass=true
    },
    emailRules(value){
      if (!value) {
        return true
      } else {
        if(/.+@.+/.test(value)) return true
        else return false||"E-mail must be valid"
      }
    },
    async updateProfile(){
      this.loading = true
      if(this.$refs.form.validate()){
        await api.post('/auth/update-profile', this.user, async (res) => {
          if(res.status){
            if(res.data.status){
              this.success = true
              this.message = res.data.message
              this.error = false
              store.commit('updateUser', this.user)
            }else{
              this.success = false
              this.message = res.data.message
              this.error = true
            }
          }
        })
      }
      this.loading = false
    },
    async updatePassword(){
      this.loading = true
      if(this.$refs.formpass.validate()){
        await api.post('/auth/update-password', this.password, async (res) => {
          // if(res.status){
            if(res.status){
              this.success = true
              this.message = res.data.message
              this.error = false
            }else{
              this.success = false
              this.message = res.data.message
              this.error = true
            }
          // }
        })
      }
      this.password = {}
      this.loading = false
    }
  },
};
</script>

<style>
  html { 
    overflow-y: auto !important;
  }
  body{
    font-family: 'Quicksand', sans-serif;
  }
  ul{
    text-align: left;
  }
</style>