<template>
  <!-- <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list dense>
        <v-list-item @click.stop="" to="/login">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="indigo"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Simulasi UNBK TAKTIS UN 2020 untuk SMP/MTs</v-toolbar-title>
    </v-app-bar>

    <v-content> -->
      <v-container class="fill-height" fluid>
        <v-flex :class="{'py-5': $vuetify.breakpoint.smAndDown}">
            <v-layout row justify-center class="mt-5">
              <v-flex xs10>
                <v-layout justify-center>
                  <v-img src="../assets/Logo 1.png" max-height="100" max-width="300"></v-img>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- <v-layout row justify-center class="pa-10">
              <v-flex sm10>
                <div :class="{'text-center display-1 font-weight-bold dark-grey--text':$vuetify.breakpoint.smAndDown, 'text-center display-3 font-weight-bold dark-grey--text':$vuetify.breakpoint.mdAndUp}">Simulasi UNBK TAKTIS UN 2020 <br> untuk SMP/MTs</div>
              </v-flex>
            </v-layout> -->
            <v-layout row justify-center class="">
              <v-flex xs12 xl10>
                <v-layout row justify-center class="" v-if="skeleton">
                  <v-flex xs10 md6 lg3 v-for="n in 1" :key="n" :class="{'pa-0 py-6': $vuetify.breakpoint.smAndDown, 'pa-6': $vuetify.breakpoint.mdAndUp}">
                    <v-skeleton-loader
                      class="mx-auto"
                      max-width="300"
                      height="300"
                      type="card"
                    ></v-skeleton-loader>
                  </v-flex>
                </v-layout>
                <v-layout row justify-center class="" v-else>
                  <v-flex v-for="mapel in mapels" :key="mapel.id" xs10 md6 lg3 :class="{'pa-0 py-6': $vuetify.breakpoint.smAndDown, 'pa-6': $vuetify.breakpoint.mdAndUp}">
                    <v-card class="mx-auto" min-width="300" max-width="300" ripple :to="'/paket/'+mapel.id" hover :loading="loading[0]">
                      <v-img :src="mapel.cover" width="300" height="433px" v-if="mapel.cover">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-img src="../assets/inggris1.jpeg" width="300" height="433px" v-else>
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-card-title class="font-weight-bold">
                        {{mapel.nama_mapel}}
                      </v-card-title>
                      <v-card-subtitle class="pt-2">
                        <p class="black--text font-weight-bold">
                        Jenjang {{mapel.jenjang}}<br>
                        Kelas {{mapel.grade}}<br>
                        {{mapel.nomor_produk}}<br>
                        </p>
                        <v-chip color="primary" text-color="white">
                          {{mapel.paket}} Paket
                        </v-chip>
                      </v-card-subtitle>
                    </v-card>
                  </v-flex>                  
                  <!-- <v-flex xs10 md6 lg3 :class="{'pa-0 py-6': $vuetify.breakpoint.smAndDown, 'pa-6': $vuetify.breakpoint.mdAndUp}">
                    <v-card class="mx-auto" max-width="344" hover @click="pilihPaket(1)" :loading="loading[1]">
                      <v-img src="../assets/image 5.png" height="200px"></v-img>

                      <v-card-title class="font-weight-bold">
                        Matematika
                      </v-card-title>
                      <v-card-subtitle class="pt-2">
                        <v-chip color="primary" text-color="white">
                          3 Paket
                        </v-chip>
                      </v-card-subtitle>
                    </v-card>
                  </v-flex>           
                  <v-flex xs10 md6 lg3 :class="{'pa-0 py-6': $vuetify.breakpoint.smAndDown, 'pa-6': $vuetify.breakpoint.mdAndUp}">
                    <v-card class="mx-auto" max-width="344" hover @click="pilihPaket(2)" :loading="loading[2]">
                      <v-img src="../assets/image 6.png" height="200px"></v-img>

                      <v-card-title class="font-weight-bold">
                        IPA
                      </v-card-title>
                      <v-card-subtitle class="pt-2">
                        <v-chip color="primary" text-color="white">
                          3 Paket
                        </v-chip>
                      </v-card-subtitle>
                    </v-card>
                  </v-flex>           
                  <v-flex xs10 md6 lg3 :class="{'pa-0 py-6': $vuetify.breakpoint.smAndDown, 'pa-6': $vuetify.breakpoint.mdAndUp}">
                    <v-card class="mx-auto" max-width="344" hover @click="pilihPaket(3)" :loading="loading[3]">
                      <v-img src="../assets/image 7.png" height="200px"></v-img>

                      <v-card-title class="font-weight-bold">
                        Bahasa Indonesia
                      </v-card-title>
                      <v-card-subtitle class="pt-2">
                        <v-chip color="primary" text-color="white">
                          3 Paket
                        </v-chip>
                      </v-card-subtitle>
                    </v-card>
                  </v-flex>            -->
                </v-layout>
                <v-layout row class="lighter-grey--text caption font-weight-thin text-center" justify-center>Copyright {{ new Date().getFullYear() }} © Grafindo Media Pratama. All Right Reserved.</v-layout>
              </v-flex>
            </v-layout>
        </v-flex>
      </v-container>
    <!-- </v-content>
    <v-footer
      color="indigo"
      app
    >
      <span class="white--text">&copy; 2019</span>
    </v-footer>
  </v-app> -->
</template>

<script>
  // import store from '../store/store'
  import * as api from '@/api'

  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: false,
      loading: [
        false,
        false,
        false,
        false
      ],
      login: false,
      mapels: [],
      index: 0,
      nameRules: [
        v => !!v || 'Name is required',
        // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      emailRules: [
        v => !!v || 'Email is required',
        // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      errored: false,
      skeleton: false,
      dialog: false,
      emailGuru: '',
      namaGuru: '',
      valid: true
    }),
    async mounted(){
      await this.get()
    },
    methods:{
      pilihPaket(){
        // if (this.$refs.guru.validate()) {
        //   localStorage.setItem('namaguru', this.namaGuru)
        //   localStorage.setItem('emailguru', this.emailGuru)
          setTimeout(() => {
            this.$router.push('/paket/'+this.mapels[this.index].id);    
          }, 500);
        // }
      },
      async get(){
        this.skeleton = true
        // await axios.get(process.env.VUE_APP_API_URL+'/mapel')
        // .then(response => {
        //   // console.log(response.data)
        //   this.mapels = response.data
        // })
        // .catch(error => {
        //   // console.log(error)
        //   this.errored = error
        // })
        // .finally(() => {
        //   setTimeout(()=>{
        //     this.skeleton = false
        //   }, 100)
        // })
        await api.get('mapel', {}, async (res) => {
          if (res.status) {
            this.mapels = res.data
            setTimeout(()=>{
              this.skeleton = false
            }, 100)
          }
        })

      },
      openDialog(index){
        this.index = index
        this.dialog = true
      }
    },
    watch: {

    },
    
  }
</script>
