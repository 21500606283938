import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import ForgotPassword from './views/ForgotPassword.vue'
import Register from './views/Register.vue'
import Hasil from './views/Hasil.vue'
import Paket from './views/Paket.vue'
import Soal from './views/Soal.vue'
import P404 from './views/404.vue'
import store from './store/store'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        isHome: true,
        noLogo: true,
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        noToolbar: true,
        cantAccessAfterAuth: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        noToolbar: true,
        cantAccessAfterAuth: true
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        noToolbar: true,
        cantAccessAfterAuth: true
      }
    },
    {
      path: '/paket/:id',
      name: 'paket',
      component: Paket,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/paket/:id_mapel/soal/:id_paket',
      name: 'soal',
      component: Soal,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/hasil',
      name: 'hasil',
      component: Hasil,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '*',
      name: '404',
      component: P404
    },
  ]
})

router.beforeEach(async(to, from, next) => {
  await store.restored.then(async() => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
        next()
        return
      }
      next('/login') 
    } else if(to.matched.some(record => record.meta.cantAccessAfterAuth)){
      if (store.getters.isLoggedIn) {
        next('/')
        return
      }
      next() 
    }
    else {
      next() 
    }
  })
})

export default router
