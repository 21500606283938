import axios from 'axios'
import wrapper from 'axios-cache-plugin'
import store from '../store/store'

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

const api = wrapper(http, {
    maxCacheSize: 15,
    ttl: 60000
})

const getUrl = (url)=>{
    let token = store.state.token
    if(token) return url+'?access_token='+token
    return url
}

const getBody = (data)=>{
    return data
}

export const post = async (url, body, callback) => {
    body = getBody(body)
  
    await api.post(getUrl(url), body).then(res => {
      callback({
        status: true,
        data: res.data
      })
    }).catch(err => {
      if (err.response) {
        // store.commit('UPDATE_ERROR_MESSAGE', {'value': err.response.data.error, type: 'popup', count: 1})
        // store.commit('TRIGGER_POPUP', true)
        if(err.response.status===403){
          store.dispatch('logout')
        }else{
          callback({
            status: false,
            message: err.response.data.error,
            data: err.response.data,
          })
        }
      } else {
        callback({
          status: false,
          message: 'Connection Timed Out'
        })
      }
    }).catch(() => {
      callback({
        status: false,
        message: 'Connection Timed Out'
      })
    })
  }
  
  export const destroy = async (url, callback) => {
    await api.delete(getUrl(url)).then(res => {
      callback({
        status: true,
        data: res.data
      })
    }).catch(err => {
      if (err.response) {
        // store.commit('UPDATE_ERROR_MESSAGE', {'value': err.response.data.error})
        callback({
          status: false,
          message: err.response.data.error,
          data: err.response.data,
        })
      }
    }).catch(() => {
      callback({
        status: false,
        message: 'Connection Timed Out'
      })
    })
  }
  
  export const get = (url, params = {}, callback) => {
    api.get(getUrl(url), {params: params}).then(res => {
      callback({
        status: true,
        data: res.data
      })
    }).catch(err => {
      if (err.response){
        if(err.response.status===403){
          store.dispatch('logout')
        }else{
          callback({
            status: false,
            message: err.response.data.error,
            data: err.response.data,
          })
        }
      }
      else{
        callback({
          status: false,
          message: 'Connection Timed Out'
        })
      }
    })
  }