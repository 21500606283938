<template>
    <v-container class="fill-height" fluid>
        <v-flex style="padding-top: 90px">
            <v-layout row justify-center>
                <v-flex xs12 sm8 md6 lg4 :xl3="!success" :xl4="success"  class="pa-10">
                    <v-card class="elevation-4 pa-8 pt-0 mx-auto">
                        <v-form ref="form" lazy-validation v-on="{ submit: !success ? $event => verify($event) : $event => signup($event) }">
                            <v-card-text>
                                <v-card width="150" height="150" class="pa-8 mx-auto round-card" color="#FEC74E">
                                    <v-layout justify-center>
                                        <v-img src="../assets/logo.png" max-width="100" max-height="100">

                                        </v-img>
                                    </v-layout>
                                </v-card>
                                <v-flex v-if="!success">
                                    <div class="headline text-center pa-4 font-weight-black primary--text">SIGN UP</div>
                                    <v-alert type="error" v-if="error&&message">
                                        {{message}}
                                    </v-alert>
                                    <v-alert type="success" v-if="success&&message">
                                        {{message}}
                                    </v-alert>
                                    <v-text-field
                                    label="Kode Unik"
                                    name="kode_unik"
                                    prepend-icon="mdi-key"
                                    type="text"
                                    v-model="kode_unik"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex v-else>
                                    <div class="headline text-center pa-4 font-weight-black primary--text">PROFILE</div>
                                    <v-alert type="error" v-if="error && messages.username">
                                        <p v-for="(messeji, i) in messages.username" :key="i">
                                            {{ messeji }}
                                        </p>
                                    </v-alert>
                                    <!-- <v-alert type="success" v-if="success">
                                        {{message}}
                                    </v-alert> -->
                                    <v-col cols="12">
                                        <v-text-field label="Username" v-model="user.username" :rules="requiredRules" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                        id="password"
                                        label="Password"
                                        name="password"                                    
                                        :rules="requiredRules"                           
                                        :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="show1 ? 'text' : 'password'"
                                        @click:append="show1 = !show1"
                                        v-model="user.password"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                        id="confirmPassword"
                                        label="Confirm Password"
                                        name="confirm_password"     
                                        :rules="passwordRules"                           
                                        :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="show2 ? 'text' : 'password'"
                                        @click:append="show2 = !show2"
                                        v-model="user.confirm_password"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Nama Lengkap" :rules="requiredRules" v-model="user.nama_lengkap" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Kelas" :rules="requiredRules" v-model="user.kelas" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Nama Sekolah" :rules="requiredRules" v-model="user.sekolah"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                        label="Kota / Kab"
                                        required v-model="user.kota" :rules="requiredRules"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Alamat Email" :rules="emailRules" v-model="user.email" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="No. HP" v-model="user.no_hp"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Nama Guru" :rules="requiredRules" v-model="user.nama_guru" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Alamat Email Guru" :rules="requiredEmailRules" v-model="user.email_guru" required></v-text-field>
                                    </v-col>
                                </v-flex>
                            </v-card-text>
                            <v-card-actions>
                                <!-- <v-spacer></v-spacer> -->
                                <v-layout justify-center wrap>
                                    <v-btn type="submit" block rounded color="primary" class="font-weight-bold" :disabled="loading" :loading="loading" x-large>Sign Up</v-btn>
                                    <div class="ma-7 mb-0 light-grey--text caption text-center">Sudah mempunyai akun?<v-btn text x-small class="text-none font-weight-bold text-center" color="primary" to="/login">Login disini</v-btn></div>
                                </v-layout>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                    <v-dialog v-model="dialog" persistent max-width="290">
                        <v-card>
                            <v-card-title class="headline">Success</v-card-title>
                            <v-card-text>{{ message }}</v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text to="login">Login</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    
                    <v-layout row class="lighter-grey--text caption font-weight-thin text-center mt-10" justify-center>Copyright {{ new Date().getFullYear() }} © Grafindo Media Pratama. All Right Reserved.</v-layout>
                </v-flex>
            </v-layout>
        </v-flex>
    </v-container>
</template>

<script>
import * as api from '@/api'
  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
      loading: false,
      kode_unik: '',
      message: '',
      messages: [],
      error: false,
      success: false,
      user: {},
      requiredRules: [
        v => !!v || 'The input is required',
      ],
    //   emailRules: [
    //     v => /.+@.+/.test(v) || "E-mail must be valid",
    //   ],
      requiredEmailRules: [
          v => !!v || 'The input is required',
          v => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      show1: false,
      show2: false,
      dialog: false
    }),
    computed: {
        passwordRules() {
            return [
                () => (this.user.password === this.user.confirm_password) || 'Password must match',
                v => !!v || 'The Input is required'
            ];
        },
    },
    methods: {
        verify(event){
            event.preventDefault()
            this.loading=true
            this.$store.dispatch('verify_unique_code', {kode_unik: this.kode_unik})
            .then(() => {
                this.message = this.$store.state.message
                if(this.$store.state.status=="success"){
                    this.error = false
                    this.success = true
                }else if(this.$store.state.status=="error"){
                    this.success = false
                    this.error = true
                }
                /* eslint-disable no-console */
          // console.log(res);
          /* eslint-enable no-console */
                this.loading=false
            })
            .catch(() => {
                this.message = this.$store.state.message
                this.error = true
                this.loading=false
            })
            // setTimeout(() => {
            //     this.$router.push({name:'home',params: { login: true }});    
            // }, 1000);
        },
        emailRules(value){
            if (!value) {
                return true
            } else {
                if(/.+@.+/.test(value)) return true
                else return false||"E-mail must be valid"
            }
        },
        async signup(event){
            event.preventDefault()
            if (this.$refs.form.validate()) {
                this.loading=true
                this.user.kode_unik = this.kode_unik
                await api.post('auth/register', this.user, async (res) => {
                    if(res.status){
                        if(res.data.status){
                            this.dialog = true
                            this.message = res.data.message
                            this.loading = false
                        }else{
                            this.messages = res.data.message
                            this.error = true
                            this.loading=false
                            
                        }
                    }else{
                        this.message = ''
                        this.messages = res.data.errors
                        this.error = true
                        this.loading=false
                        /* eslint-disable no-console */
        //   console.log(res.data.errors);
          /* eslint-enable no-console */
                    }
                })
            }
        }
    }
  }
</script>

<style>
    .round-card{
        border-radius: 100px !important;
        margin-top: -90px;
    }
</style>