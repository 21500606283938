<template>
    <v-container class="fill-height" fluid>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Petunjuk Paket</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn dark color="accent" class="black--text" @click="mulai" v-if="mapel.paket_ujian&&mapel.paket_ujian[item]">Mulai</v-btn>
                </v-toolbar>
                <v-container class="fill-height">
                    <v-layout row justify-center :class="{'pa-4': $vuetify.breakpoint.smAndDown, 'pa-10': $vuetify.breakpoint.mdAndUp}">
                        <v-flex xs10>
                            <div class="text-center font-weight-bold dark-grey--text" v-if="mapel.paket_ujian&&mapel.paket_ujian[item]">
                                <p class="headline font-weight-bold">Petunjuk Penggunaan Paket {{mapel.paket_ujian[item].nama_paket}}</p>
                                <p v-html="mapel.paket_ujian[item].petunjuk"></p>
                            </div>
                        </v-flex>
                    </v-layout>
                    </v-container>
            </v-card>
            </v-dialog>
        <v-flex :class="{'py-2': $vuetify.breakpoint.smAndDown}">
        <v-layout row justify-center>
        <v-flex xs10 md10 xl8 v-if="!loading">
            <v-layout row wrap justify-content>
                <v-btn class="mb-5 text-none" depressed color="grey lighten-2" to="/"><v-icon>mdi-chevron-left</v-icon>Kembali</v-btn>
                <v-flex xs12>
                <v-layout row justify-center>
                    <v-flex xs12 :lg6="mapel.paket_ujian&&mapel.paket_ujian[item]" class="pa-3">
                        <v-card :loading="loading">
                            <v-toolbar
                                color="primary"
                                dark
                                flat
                                class="px-5"
                            >
                                <v-toolbar-title>{{mapel.nama_mapel}}</v-toolbar-title>
                                <!-- <v-spacer></v-spacer>
                                <v-btn color="accent" @click="mulai">
                                    <span class="black--text font-weight-bold text-none">Mulai</span>
                                </v-btn> -->
                            </v-toolbar>
                            <v-card-text class="pa-0">
                                <v-layout row justify-center v-if="mapel.paket_ujian&&mapel.paket_ujian[item]">
                                        <v-container :class="{'pa-2 fill-height': $vuetify.breakpoint.mdAndUp}">
                                            <v-flex xs11 style="height:100%">
                                                <v-layout row justify-center style="height:100%">
                                                    <v-container :class="{'fill-height pa-4': $vuetify.breakpoint.mdAndUp}" style="height:100%">
                                                        <v-list shaped width="100%" height="100%">
                                                            <v-subheader class="ml-4 title font-weight-black">Daftar Isi</v-subheader>
                                                            <v-list-item-group v-model="item" color="primary" mandatory class="scroll">
                                                                <v-list-item
                                                                v-for="paket in mapel.paket_ujian"
                                                                :key="paket.id"
                                                                >
                                                                    <v-list-item-content class="pl-4" @click="openInstruction">
                                                                        <v-list-item-title v-text="paket.nama_paket"></v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list-item-group>
                                                        </v-list>
                                                    </v-container>
                                                </v-layout>
                                            </v-flex>
                                        </v-container>
                                </v-layout>
                                <v-container class="fill-height" v-else>
                                    <v-layout row justify-center class="mt-4">
                                        <p class="font-weight-bold title">Belum ada paket</p>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 lg6 class="pa-3" v-if="$vuetify.breakpoint.mdAndUp && mapel.paket_ujian&&mapel.paket_ujian[item]">
                        <v-card :loading="loading">
                            <v-toolbar
                                color="primary"
                                dark
                                flat
                                class="px-5"
                            >
                                <v-toolbar-title>Petunjuk Penggunaan</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn color="accent" v-if="$vuetify.breakpoint.mdAndUp && mapel.paket_ujian&&mapel.paket_ujian[item]" @click="mulai">
                                    <span class="black--text font-weight-bold text-none">Mulai</span>
                                </v-btn>
                            </v-toolbar>
                            <v-card-text>
                                <v-container class="fill-height">
                                    <v-layout row justify-center>
                                        <v-flex xs10>
                                            <div class="text-center font-weight-bold dark-grey--text" v-if="mapel.paket_ujian&&mapel.paket_ujian[item]">
                                                <p class="headline font-weight-bold">{{mapel.paket_ujian[item].nama_paket}}</p>
                                                <p v-html="mapel.paket_ujian[item].petunjuk"></p>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
                </v-flex>
                <!-- <v-flex xs12>
                <v-card :loading="loading" height="700">
                    <v-toolbar
                        color="primary"
                        dark
                        flat
                        class="px-5"
                    >
                        <v-toolbar-title>{{mapel.nama_mapel}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="accent" v-if="$vuetify.breakpoint.mdAndUp && mapel.paket_ujian&&mapel.paket_ujian[item]" @click="mulai">
                            <span class="black--text font-weight-bold text-none">Mulai</span>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="pa-0">
                    <v-layout row justify-center v-if="mapel.paket_ujian&&mapel.paket_ujian[item]">
                        <v-flex xs12 lg6 height="100%">
                            <v-container :class="{'pa-2 fill-height': $vuetify.breakpoint.mdAndUp}">
                                <v-flex xs11 style="height:100%">
                                    <v-layout row justify-center style="height:100%">
                                        <v-container :class="{'fill-height pa-4': $vuetify.breakpoint.mdAndUp}" style="height:100%">
                                            <v-list shaped width="100%" height="100%">
                                                <v-subheader class="ml-4 title font-weight-black">Paket Ujian</v-subheader>
                                                <v-list-item-group v-model="item" color="primary" mandatory class="scroll" style="height:545px">
                                                    <v-list-item
                                                    v-for="paket in mapel.paket_ujian"
                                                    :key="paket.id"
                                                    >
                                                        <v-list-item-content class="pl-4" @click="openInstruction">
                                                            <v-list-item-title v-text="paket.nama_paket"></v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-container>
                                    </v-layout>
                                </v-flex>
                            </v-container>
                        </v-flex>
                        <v-flex xs12 lg6 v-if="$vuetify.breakpoint.mdAndUp">
                            <v-container class="fill-height">
                            <v-layout row justify-center :class="{'pa-4': $vuetify.breakpoint.smAndDown, 'pa-10': $vuetify.breakpoint.mdAndUp}">
                                <v-flex xs10>
                                    <div class="text-center font-weight-bold dark-grey--text" >
                                        <p class="headline font-weight-bold">Petunjuk Penggunaan Paket {{mapel.paket_ujian[item].nama_paket}}</p>
                                        <p v-html="mapel.paket_ujian[item].petunjuk"></p>
                                    </div>
                                </v-flex>
                            </v-layout>
                            </v-container>
                        </v-flex>
                    </v-layout>
                    <v-container class="fill-height" v-else>
                        <v-layout row justify-center class="mt-4">
                            <p class="font-weight-bold title">Belum ada paket</p>
                        </v-layout>
                    </v-container>
                    </v-card-text>
                </v-card>
                </v-flex> -->
            </v-layout>
            <v-layout row class="lighter-grey--text caption font-weight-thin mt-5 text-center" justify-center>Copyright {{ new Date().getFullYear() }} © Grafindo Media Pratama. All Right Reserved.</v-layout>
        </v-flex>
        <v-dialog
            v-model="loading"
            persistent
            width="300"
            >
            <v-card
                color="accent"
                dark
            >
                <v-card-text class="pt-5">
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
            </v-dialog>
        </v-layout>
        </v-flex>
    </v-container>
</template>

<script>
// import axios from 'axios'
import * as api from '@/api'
import store from '../store/store'
  export default {
    data(){
        return {
            loading: false,
            item: 0,
            // pakets: [],
            dialog: false,
            mapel_id: this.$route.params.id,
            mapel: {}
        }
    },
    async mounted(){
        await this.get()
    },
    methods:{
        mulai(){
            store.commit('removeMapel')
            this.$router.push('/paket/'+this.mapel_id+'/soal/'+this.mapel.paket_ujian[this.item].id)
        },
        openInstruction(){
            if(this.$vuetify.breakpoint.smAndDown) this.dialog = true
        },
        async get(){
            this.loading = true
            // await axios.get(process.env.VUE_APP_API_URL+'/mapel/'+this.mapel_id+'/paket')
            // .then(response => {
            // // console.log(response.data)
            // this.mapel = response.data
            // })
            // .catch(error => {
            // // console.log(error)
            // this.errored = error
            // })
            // .finally(() => this.loading = false)
            await api.get('/mapel/'+this.mapel_id+'/paket', {}, async (res) => {
                if (res.status) {
                    this.mapel = res.data
                    // setTimeout(()=>{
                        this.loading = false
                    // }, 100)
                }
            })
        }
    },
    watch: {

    }
  }
</script>

<style scoped>
    .scroll{
        overflow-y: auto !important;
        height: 100%;
    }
</style>
