import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/api'
import router from '../router'
import createPersistedState from "vuex-persistedstate";
import LocalForage from 'localforage'
import Cookies from 'js-cookie';
// import SecureLS from "secure-ls";
import VuexPersistence from 'vuex-persist'
// var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)
LocalForage.config({
  driver      : LocalForage.IndexedDB,
  name        : 'CBTGRAFINDO',
  version     : 1.0,
  storeName   : 'cbt-grafindo'
});

const vuexPersist = new VuexPersistence({
  // strictMode: true, // This **MUST** be set to true
  key: 'soal',
  storage: LocalForage,
  asyncStorage: true,
  reducer: (state) => ({ mapel: state.mapel }),
  // filter: (mutation) => mutation.type === 'dogBark'
})

export default new Vuex.Store({
  state: {
    status: '',
    token: null,
    user : {},
    kode_unik: '',
    reset_code: '',
    message: '',
    mapel: {}
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, data){
      state.status = 'success'
      state.token = data.token
      state.user = data.user
    },
    auth_error(state, message){
      state.status = 'error'
      state.message = message
    },
    verified_unique_code(state, data){
      state.kode_unik = data.code
      state.status = data.status?'success':'error'
      state.message = data.message
    },
    verified_reset_code(state, data){
      state.reset_code = data.code
      state.status = data.status?'success':'error'
      state.message = data.message
    },
    logout(state){
      state.status = ''
      state.token = null
      state.user = {}
      state.kode_unik = ''
      state.reset_code = ''
      state.mapel= {}
    },
    updateUser(state, data){
      state.user = data
    },
    updateMapel(state, data){
      state.mapel = data
    },
    removeMapel(state){
      state.mapel = {}
    }
  },
  actions: {
    async login({commit}, user){
      commit('auth_request')
      await api.post('auth/login', user, async (res) => {
        if (res.status) {          
          commit('auth_success', res.data)
        }else{
          commit('auth_error', res.data.message)
        }
      })
    },
    async logout({commit}){
      if(this.state.token!=null){
        await api.get('auth/logout', {logout: true}, async (res) => {
          if (res.status) {
            commit('logout')
          }
        })
        commit('logout')
        router.push('/login')
      }
    },
    async verify_unique_code({commit}, data){
      commit('auth_request')
      await api.post('auth/verify-unique-code', data, async (res) => {
        if(res.status){
          commit('verified_unique_code', res.data)
        }
      })
    },
    async verify_reset_code({commit}, data){
      commit('auth_request')
      await api.post('auth/verify-reset', data, async (res) => {
        if(res.status){
          commit('verified_reset_code', res.data)
        }
      })
    }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
  },
  plugins: [
    createPersistedState({
      paths: ['token', 'user', 'kode_unik', 'reset_code'],
      key: 'auth',
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 365, secure: false }),
        removeItem: key => Cookies.remove(key)
      },
      // reducer (val) {                                
      //   if(val.token === null) {
      //     return {}
      //   }
      //   return val
      // }
    }),
    // createPersistedState({
    //   paths: ['mapel'],
    //   key: 'soal',
    //   storage: {
    //     getItem: key => ls.get(key),
    //     setItem: (key, value) => ls.set(key, value),
    //     removeItem: key => ls.remove(key)
    //   }
    // })
    // createPersistedState({
    //   paths: ['mapel'],
    //   key: 'soal',
    //   storage: {
    //     getItem: key => LocalForage.getItem(key),
    //     setItem: (key, value) => LocalForage.setItem(key, value),
    //     removeItem: key => LocalForage.removeItem(key)
    //   }
    // }),
    vuexPersist.plugin
  ]
})