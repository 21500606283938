<template>
    <v-container class="fill-height" fluid>
        <v-flex style="padding-top: 90px">
            <v-layout row justify-center>
                <v-flex xs12 sm8 md6 lg4 xl3  class="pa-10">
                    <v-card class="elevation-4 pa-8 pt-0 mx-auto">
                        <v-form ref="form" lazy-validation v-on="{ submit: step==1 ? $event => search($event) : step==2? $event => verify($event) : $event => updatePassword($event) }">
                            <v-card-text>
                                <v-card width="150" height="150" class="pa-8 mx-auto round-card" color="#FEC74E">
                                    <v-layout justify-center>
                                        <v-img src="../assets/logo.png" max-width="100" max-height="100">

                                        </v-img>
                                    </v-layout>
                                </v-card>
                                <v-flex v-if="step==1">
                                    <div class="headline text-center pa-4 font-weight-black primary--text">FORGOT PASSWORD</div>
                                    <v-alert type="error" v-if="error">
                                        {{message}}
                                    </v-alert>
                                    <v-alert type="success" v-if="success">
                                        {{message}}
                                    </v-alert>
                                    <v-text-field
                                    label="Username"
                                    name="username"
                                    :rules="requiredRules"
                                    prepend-icon="mdi-account"
                                    type="text"
                                    v-model="username"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex v-else-if="step==2">
                                    <div class="headline text-center pa-4 font-weight-black primary--text">KODE RESET</div>
                                    <v-alert type="error" v-if="error">
                                        {{message}}
                                    </v-alert>
                                    <v-alert type="success" v-if="success">
                                        {{message}}
                                    </v-alert>
                                    Kode Reset Password telah dikirim ke alamat email siswa atau guru, masukkan kode yang tertera kedalam isian dibawah ini
                                    <v-text-field
                                    label="Kode Reset Password"
                                    name="pass_token"
                                    prepend-icon="mdi-key"
                                    type="text"
                                    v-model="pass_token"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex v-else>
                                    <div class="headline text-center pa-4 font-weight-black primary--text">BUAT PASSWORD BARU</div>
                                    <v-alert type="error" v-if="error">
                                        {{message}}
                                    </v-alert>
                                    <!-- <v-alert type="success" v-if="success">
                                        {{message}}
                                    </v-alert> -->
                                    <v-col cols="12">
                                        <v-text-field
                                        id="password"
                                        label="Password Baru"
                                        name="password"                                    
                                        :rules="requiredRules"                           
                                        :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="show1 ? 'text' : 'password'"
                                        @click:append="show1 = !show1"
                                        v-model="password.password"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                        id="confirmPassword"
                                        label="Confirm Password Baru"
                                        name="confirm_password"     
                                        :rules="passwordRules"                           
                                        :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="show2 ? 'text' : 'password'"
                                        @click:append="show2 = !show2"
                                        v-model="password.confirm_password"
                                        ></v-text-field>
                                    </v-col>
                                </v-flex>
                            </v-card-text>
                            <v-card-actions>
                                <!-- <v-spacer></v-spacer> -->
                                <v-layout justify-center wrap>
                                    <v-btn type="submit" block rounded color="primary" class="font-weight-bold" :disabled="loading" :loading="loading" x-large>{{btntext}}</v-btn>
                                    <div class="ma-7 mb-0 light-grey--text caption text-center">Sudah mempunyai akun?<v-btn text x-small class="text-none font-weight-bold text-center" color="primary" to="/login">Login disini</v-btn></div>
                                </v-layout>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                    <v-dialog v-model="dialog" persistent max-width="290">
                        <v-card>
                            <v-card-title class="headline">Success</v-card-title>
                            <v-card-text>{{ message }}</v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text to="login">Login</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    
                    <v-layout row class="lighter-grey--text caption font-weight-thin text-center mt-10" justify-center>Copyright {{ new Date().getFullYear() }} © Grafindo Media Pratama. All Right Reserved.</v-layout>
                </v-flex>
            </v-layout>
        </v-flex>
    </v-container>
</template>

<script>
import * as api from '@/api'
  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
      loading: false,
      username: '',
      pass_token: '',
      step: 1,
      password: {},
      message: '',
      messages: [],
      error: false,
      success: false,
      btntext: 'Kirim kode reset',
      user: {},
      requiredRules: [
        v => !!v || 'The input is required',
      ],
      emailRules: [
        v => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      requiredEmailRules: [
          v => !!v || 'The input is required',
          v => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      show1: false,
      show2: false,
      dialog: false
    }),
    computed: {
        passwordRules() {
            return [
                () => (this.password.password === this.password.confirm_password) || 'Password must match',
                v => !!v || 'The Input is required'
            ];
        },
    },
    methods: {
        async search(event){
            event.preventDefault()
            if (this.$refs.form.validate()) {
                this.loading=true
                this.user.kode_unik = this.kode_unik
                await api.post('auth/reset-pass', {username: this.username}, async (res) => {
                    if(res.status){
                        if(res.data.status){
                            this.message = res.data.message
                            this.loading = false
                            this.step = 2
                            this.btntext = 'submit'
                        }else{
                            this.messages = res.data.message
                            this.error = true
                            this.loading=false
                        }
                    }else{
                        this.message = res.data.message
                        this.error = true
                        this.loading=false
                        /* eslint-disable no-console */
        //   console.log(res.data.errors);
          /* eslint-enable no-console */
                    }
                })
            }
        },
        verify(event){
            event.preventDefault()
            this.loading=true
            this.$store.dispatch('verify_reset_code', {username: this.username, pass_token: this.pass_token})
            .then(() => {
                this.message = this.$store.state.message
                if(this.$store.state.status=="success"){
                    this.error = false
                    this.success = true
                    this.step = 3
                    this.btntext = 'ubah password'
                }else if(this.$store.state.status=="error"){
                    this.success = false
                    this.error = true
                }
                /* eslint-disable no-console */
          // console.log(res);
          /* eslint-enable no-console */
                this.loading=false
            })
            .catch(() => {
                this.message = this.$store.state.message
                this.error = true
                this.loading=false
            })
            // setTimeout(() => {
            //     this.$router.push({name:'home',params: { login: true }});    
            // }, 1000);
        },
        async updatePassword(event){
            event.preventDefault()
            if (this.$refs.form.validate()) {
                this.loading=true
                this.password.username = this.username
                await api.post('auth/update-pass', this.password, async (res) => {
                    if(res.status){
                        if(res.data.status){
                            this.dialog = true
                            this.message = res.data.message
                            this.loading = false
                        }else{
                            this.messages = res.data.message
                            this.error = true
                            this.loading=false
                            
                        }
                    }else{
                        this.message = res.data.message
                        this.error = true
                        this.loading=false
                        /* eslint-disable no-console */
        //   console.log(res.data.errors);
          /* eslint-enable no-console */
                    }
                })
            }
        }
    }
  }
</script>

<style>
    .round-card{
        border-radius: 100px !important;
        margin-top: -90px;
    }
</style>