<template>
        <v-container class="fill-height" fluid>
            <v-flex>
                <v-layout row justify-center>
                    <v-card>
                        <v-card-title>
                            <v-layout row>
                                <v-flex lg12 style="margin-left: 10px; padding-top: 10px; text-align: center;">
                                    <p class="font-weight-black display-2 pt-5 pb-0 mb-0">404!</p>
                                    <p>Whoops</p>
                                    <p class="condensed">Sepertinya anda mengakses halaman yang salah<br/>atau halaman belum tersedia.</p>
                                    <v-btn color="warning" @click="$router.push('/')">Kembali ke Home</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card-title>
                    </v-card>
                </v-layout>
            </v-flex>
        </v-container>
</template>

<script>
  export default {
  }
</script>
