<template>
    <v-container class="fill-height" fluid>
        <v-navigation-drawer v-model="drawer" :absolute="$vuetify.breakpoint.mdAndUp" :fixed="$vuetify.breakpoint.smAndDown" right :app="$vuetify.breakpoint.smAndDown">
            <v-container>
                <v-flex>
                    <v-layout row wrap justify-center>
                        <v-chip :class="{'accent accent--text ma-2': soal.ragu,'black black--text ma-2': soal.jawab==-1||soal.jawab==null, 'primary primary--text ma-2': soal.jawab>0}" outlined label ripple v-for="(soal, n) in mapel.soal" :key="soal.id" @click="model=n">
                            <v-sheet width="20" :class="{'accent text-center mr-2 white--text': soal.ragu, 'grey darken-1 text-center mr-2 white--text': soal.jawab==-1||soal.jawab==null, 'primary text-center mr-2 white--text': soal.jawab>0}" style="border-radius: 3px">
                                {{ n+1 }}
                            </v-sheet>
                            <div class="answer-field">{{soal.jawab_huruf}}</div>
                        </v-chip>
                    </v-layout>
                </v-flex>
            </v-container>
        </v-navigation-drawer>
        <v-flex :class="{'py-2': $vuetify.breakpoint.smAndDown}">
            <v-layout row justify-center>
                <v-flex xs10 xl8 v-if="!loading">
                    <v-layout row>
                        <v-btn class="mb-5 text-none" depressed color="grey lighten-2" @click="dialogKembali=true"><v-icon>mdi-chevron-left</v-icon>Kembali</v-btn>
                        <v-progress-linear ref="soal" :value="progress" color="accent white"></v-progress-linear>
                        <v-card :loading="loading" tile :class="{'device-height':$vuetify.breakpoint.smAndDown}" width="100%">
                            <v-toolbar color="primary" dark flat class="px-5" :extended="$vuetify.breakpoint.smAndDown&&portrait">
                                <v-toolbar-title v-if="mapel.nama_mapel">{{mapel.nama_paket}}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <template v-if="$vuetify.breakpoint.smAndDown&&portrait" #extension>
                                    <!-- <div class="mr-5 mb-5 text-center"><v-icon class="mr-1">mdi-av-timer</v-icon>{{hoursElapsed | two_digits}}:{{minutesElapsed | two_digits}}:{{secondsElapsed | two_digits}}</div> -->
                                    <div class="mr-5 mb-5 text-center"><v-icon class="mr-1">mdi-av-timer</v-icon>{{hours | two_digits}}:{{minutes | two_digits}}:{{seconds | two_digits}}</div>
                                    <v-btn color="accent" class="mb-5" @click="drawer=!drawer">
                                        <span class="black--text font-weight-bold text-none">Lembar Jawaban</span>
                                    </v-btn>
                                </template>
                                <!-- <div v-if="$vuetify.breakpoint.mdAndUp" class="mx-5"><v-icon class="mr-1">mdi-av-timer</v-icon>{{hoursElapsed | two_digits}}:{{minutesElapsed | two_digits}}:{{secondsElapsed | two_digits}}</div> -->
                                <div v-if="$vuetify.breakpoint.mdAndUp||landscape" class="mx-5"><v-icon class="mr-1">mdi-av-timer</v-icon>{{hours | two_digits}}:{{minutes | two_digits}}:{{seconds | two_digits}}</div>
                                <!-- <v-spacer></v-spacer> -->
                                <v-btn color="accent" @click="drawer=!drawer" v-if="$vuetify.breakpoint.mdAndUp||landscape">
                                    <span class="black--text font-weight-bold text-none">Lembar Jawaban</span>
                                </v-btn>
                            </v-toolbar>
                            <v-carousel touchless v-model="model" hide-delimiters :show-arrows="false" :class="{'device-height-60':$vuetify.breakpoint.smAndDown}" height="400">
                                <v-carousel-item v-for="(soal, n) in mapel.soal" :key="soal.id">
                                    <v-container :class="{'device-height-50 fill-height align-content-start': $vuetify.breakpoint.smAndDown, 'fill-height align-content-start': $vuetify.breakpoint.mdAndUp}" style="overflow-y:scroll">
                                    <v-card-title class="text--black" style="color: rgba(0,0,0,.87) !important;" :class="{'pl-5': $vuetify.breakpoint.smAndDown, 'pl-9': $vuetify.breakpoint.mdAndUp}">Soal Nomor {{ n+1 }}</v-card-title>
                                    <v-card-text :class="{'pa-8 pt-0': $vuetify.breakpoint.smAndDown, 'pa-12 pt-0': $vuetify.breakpoint.mdAndUp}">
                                        <v-layout row justify-center>
                                            <v-flex xs12 class="black--text default-font" :style="getFontSetting">
                                                <audio controls v-if="soal.audio">
                                                    <source :src="soal.audio">
                                                </audio>
                                                <p v-html="soal.soal"></p>
                                                <ol type="A">
                                                    <li v-for="pilihan in soal.pilihan_jawaban" :key="pilihan.id"><div class="boxed-option" v-html="pilihan.jawaban"></div></li>
                                                </ol>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                    </v-container>
                                </v-carousel-item>
                            </v-carousel>

                            <v-card-actions class="pa-5" v-if="$vuetify.breakpoint.mdAndUp">
                                <v-layout row justify-center wrap>
                                    <v-btn-toggle v-model="toggleRagu" color="accent" v-if="$vuetify.breakpoint.lgAndUp">
                                        <v-btn class="font-weight-bold title ma-5 ml-0 text-none ragu-btn" outlined large color="accent" @click="ragu">
                                            Ragu
                                        </v-btn>
                                    </v-btn-toggle>
                                    <v-btn class="ma-5 mr-1" large depressed color="success" @click="model--" :disabled="prev" v-if="$vuetify.breakpoint.lgAndUp">
                                        <v-icon>mdi-arrow-left-thick</v-icon>
                                    </v-btn>
                                    <v-flex sm12 lg7>
                                        <v-layout row justify-center>
                                            <v-btn-toggle v-model="jawaban" color="primary" class="mx-2">
                                                <v-layout row justify-center wrap>
                                                    <v-flex xs12 v-if="mapel.soal&&mapel.soal[model]">
                                                        <v-btn v-for="(pilihan, i) in mapel.soal[model].pilihan_jawaban" :key="pilihan.id" :value="pilihan.id" class="answer-btn font-weight-bold title ma-5" depressed large color="accent" @click="pilihJawaban(pilihan.id, huruf[i])">
                                                            {{huruf[i]}}
                                                        </v-btn>
                                                    </v-flex>
                                                </v-layout>
                                            </v-btn-toggle>
                                        </v-layout>
                                    </v-flex>
                                    <v-btn-toggle v-model="toggleRagu" color="accent" v-if="$vuetify.breakpoint.mdAndDown">
                                        <v-btn class="font-weight-bold title ma-5 text-none ragu-btn" outlined large color="accent" @click="ragu">
                                            Ragu
                                        </v-btn>
                                    </v-btn-toggle>
                                    <v-btn class="ma-5" large depressed color="success" @click="model--" :disabled="prev" v-if="$vuetify.breakpoint.mdAndDown">
                                        <v-icon>mdi-arrow-left-thick</v-icon>
                                    </v-btn>
                                    <v-btn class="ma-5" large depressed color="success" @click="model++" :disabled="next">
                                        <v-icon>mdi-arrow-right-thick</v-icon>
                                    </v-btn>
                                    <v-btn class="font-weight-bold title ma-5 mr-0 white--text text-none" depressed large color="red" @click="dialog=!dialog">
                                        Selesai
                                    </v-btn>
                                </v-layout>
                            </v-card-actions>
                            <v-toolbar bottom floating v-if="$vuetify.breakpoint.smAndDown" style="position: fixed; left:0;" :height="portrait?115:70" width="100%">
                                <v-layout justify-center wrap v-if="portrait">
                                    <v-flex xs12>
                                        <v-layout justify-center>
                                            <v-btn-toggle v-model="jawaban" color="primary" class="mx-2">
                                                <v-layout row justify-center v-if="mapel.soal&&mapel.soal[model]">
                                                    <v-btn v-for="(pilihan, i) in mapel.soal[model].pilihan_jawaban" :key="pilihan.id" :value="pilihan.id" class="answer-btn font-weight-bold title ma-1" depressed color="accent" @click="pilihJawaban(pilihan.id, huruf[i])">
                                                        {{huruf[i]}}
                                                    </v-btn>
                                                </v-layout>
                                            </v-btn-toggle>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs-12>
                                        <v-layout row justify-center>
                                            <v-btn depressed color="success" class="ma-2" @click="model--" small :disabled="prev" >
                                                <v-icon>mdi-arrow-left-thick</v-icon>
                                            </v-btn>
                                            <v-btn-toggle v-model="toggleRagu" class="ma-2" color="accent">
                                                <v-btn class="font-weight-bold text-none ragu-btn" outlined small color="accent" @click="ragu">
                                                    Ragu
                                                </v-btn>
                                            </v-btn-toggle>
                                            <v-btn class="font-weight-bold white--text text-none ma-2" small depressed color="red" @click="dialog=!dialog">
                                                Selesai
                                            </v-btn>
                                            <v-btn depressed color="success" class="ma-2" @click="model++" small :disabled="next">
                                                <v-icon>mdi-arrow-right-thick</v-icon>
                                            </v-btn>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout justify-center wrap v-else>
                                    <v-flex xs12>
                                        <v-layout justify-center align-center>
                                            <v-btn depressed color="success" class="ma-2" @click="model--" small :disabled="prev" >
                                                <v-icon>mdi-arrow-left-thick</v-icon>
                                            </v-btn>
                                            <v-btn-toggle v-model="toggleRagu" class="ma-2" color="accent">
                                                <v-btn class="font-weight-bold text-none ragu-btn" outlined small color="accent" @click="ragu">
                                                    Ragu
                                                </v-btn>
                                            </v-btn-toggle>
                                            <v-btn-toggle v-model="jawaban" color="primary" class="mx-2">
                                                <v-layout row justify-center v-if="mapel.soal&&mapel.soal[model]">
                                                    <v-btn v-for="(pilihan, i) in mapel.soal[model].pilihan_jawaban" :key="pilihan.id" :value="pilihan.id" class="answer-btn font-weight-bold title ma-1" depressed color="accent" @click="pilihJawaban(pilihan.id, huruf[i])">
                                                        {{huruf[i]}}
                                                    </v-btn>
                                                </v-layout>
                                            </v-btn-toggle>
                                            <v-btn class="font-weight-bold white--text text-none ma-2" small depressed color="red" @click="dialog=!dialog">
                                                Selesai
                                            </v-btn>
                                            <v-btn depressed color="success" class="ma-2" @click="model++" small :disabled="next">
                                                <v-icon>mdi-arrow-right-thick</v-icon>
                                            </v-btn>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </v-toolbar>
                        </v-card>
                    </v-layout>
                    <v-layout row class="lighter-grey--text caption font-weight-thin mt-6 text-center" justify-center>Copyright {{ new Date().getFullYear() }} © Grafindo Media Pratama. All Right Reserved.</v-layout>
                </v-flex>
            </v-layout>
            <v-dialog v-model="dialog" max-width="290">
                <v-card>
                    <v-card-title class="display-4">
                        <v-layout row justify-center>
                        <v-icon x-large color="red">mdi-alert</v-icon>
                        </v-layout>
                    </v-card-title>

                    <v-card-text>
                    Waktu masih tersisa anda yakin mengakhiri ujian?
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text @click="selesai">
                        Ya
                    </v-btn>

                    <v-btn color="green darken-1" text @click="dialog = false">
                        Tidak
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogHabis" persistent max-width="290">
                <v-card>
                    <v-card-title class="display-4">
                        <v-layout row justify-center>
                        <v-icon x-large color="red">mdi-alert</v-icon>
                        </v-layout>
                    </v-card-title>

                    <v-card-text class="text-center">
                    Waktu sudah habis
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text to="/hasil">
                        Hasil
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogKembali" max-width="290">
                <v-card>
                    <v-card-title class="display-4">
                        <v-layout row justify-center>
                        <v-icon x-large color="red">mdi-alert</v-icon>
                        </v-layout>
                    </v-card-title>

                    <v-card-text class="text-center">
                        Kamu yakin akan keluar?
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text :to="'/paket/'+mapel_id">
                        Ya
                    </v-btn>

                    <v-btn color="green darken-1" text @click="dialogKembali = false">
                        Tidak
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
            v-model="loading"
            persistent
            width="300"
            >
            <v-card
                color="accent"
                dark
            >
                <v-card-text class="pt-5">
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
            </v-dialog>
        </v-flex>
    </v-container>
</template>

<script>
// import axios from 'axios'
import * as api from '@/api'
import store from '../store/store'
  export default {
    data(){
        return {
            loading: false,
            model: 0,
            jawaban: -1,
            toggleRagu: -1,
            drawer: false,
            next: true,
            prev: true,
            progress: 0,
            dialog: false,
            dialogHabis: false,
            dialogKembali: false,
            mapel_id: this.$route.params.id_mapel,
            paket_id: this.$route.params.id_paket,
            // mapel: JSON.parse(localStorage.getItem('mapel')),
            // mapel: {},
            huruf: [
                'A',
                'B',
                'C',
                'D',
                'E'
            ],
            now: Math.trunc((new Date()).getTime() / 1000),
            // target: Math.trunc((new Date()).getTime() / 1000),
            // start: Math.trunc((new Date()).getTime() / 1000),
            interval: '',
            portrait: this.Window.matchMedia("(orientation: portrait)").matches,
            landscape: this.Window.matchMedia("(orientation: landscape)").matches
        }
    },
    methods:{
        selesai(){
            this.loading = true
            this.mapel.elapsedTime = this.$options.filters.two_digits(this.hoursElapsed)+':'+this.$options.filters.two_digits(this.minutesElapsed)+':'+this.$options.filters.two_digits(this.secondsElapsed)
            // localStorage.setItem('mapel', JSON.stringify(this.mapel))
            store.commit('updateMapel', this.mapel)
            clearInterval(this.interval)
            setTimeout(() => {
                this.$router.push('/hasil')
            }, 1000);
        },
        async get(){
            this.loading = true
            // await axios.get(process.env.VUE_APP_API_URL+'/mapel/'+this.mapel_id+'/paket/'+this.paket_id+'/soal')
            // .then(response => {
            //     this.mapel = response.data
            // })
            // .catch(error => {
            //     this.errored = error
            // })
            // .finally(() => {

            // })
            if(Object.keys(this.mapel).length === 0){
                await api.get('/mapel/'+this.mapel_id+'/paket/'+this.paket_id+'/soal', {}, async (res) => {
                    if (res.status) {
                        store.commit('updateMapel', res.data)
                        // if(!this.mapel) {
                        //     this.mapel = res.data
                        //     store.commit('updateMapel', res.data)
                        // }
                        var date = new Date()
                        this.now = Math.trunc(date.getTime()/1000)
                        date.setMinutes(date.getMinutes()+this.mapel.waktu)

                        // if(!this.mapel.start){
                            this.mapel.start = this.now
                            this.mapel.target = Math.trunc(date.getTime()/1000)
                            // localStorage.setItem('target', Math.trunc(date.getTime()/1000))
                            // localStorage.setItem('start', this.now)
                            // store.commit('updateMapel', this.mapel)
                        // }

                        // if(localStorage.getItem('mapel')) this.mapel = JSON.parse(localStorage.getItem('mapel'))
                        // else {
                        //     localStorage.setItem('target', Math.trunc(date.getTime()/1000))
                        //     localStorage.setItem('start', this.now)
                        // }

                        // if(localStorage.getItem('target')) this.target = localStorage.getItem('target')
                        // else this.target = Math.trunc(date.getTime()/1000)

                        // if(localStorage.getItem('start')) this.start = localStorage.getItem('start')
                        // else this.start = this.now
                        store.commit('updateMapel', this.mapel)
                        this.startSoal()
                    }
                })
            }
            if(Object.keys(this.mapel).length > 0){
                this.startSoal()
            }
            // store.commit('updateMapel', this.mapel)
        },
        pilihJawaban(id, huruf){
            var _id = -1
            var _huruf = ''
            if(this.mapel.soal[this.model].jawab!=id){
                _id = id
                _huruf = huruf
            }
            this.$set(this.mapel.soal[this.model], 'jawab', _id)
            this.$set(this.mapel.soal[this.model], 'jawab_huruf', _huruf)
            this.progress = (this.mapel.soal.filter(x=>x.jawab&&x.jawab!=-1).length/this.mapel.soal_count)*100
            // localStorage.setItem('mapel', JSON.stringify(this.mapel))
            store.commit('updateMapel', this.mapel)
        },
        ragu(){
            var ragu = false
            if(!this.mapel.soal[this.model].ragu){
                ragu = true
            }
            this.$set(this.mapel.soal[this.model], 'ragu', ragu)
            // localStorage.setItem('mapel', JSON.stringify(this.mapel))
            store.commit('updateMapel', this.mapel)
        },
        startSoal(){
            if(this.mapel.soal_count>1) this.next = false
            if(this.now>=this.target){
                this.dialogHabis = true
                this.mapel.elapsedTime = this.$options.filters.two_digits(this.hoursElapsed)+':'+this.$options.filters.two_digits(this.minutesElapsed)+':'+this.$options.filters.two_digits(this.secondsElapsed)
                // localStorage.setItem('mapel', JSON.stringify(this.mapel))
                store.commit('updateMapel', this.mapel)
            }else{
                this.interval = setInterval(() => {
                    this.now = Math.trunc((new Date()).getTime() / 1000);
                    if(this.now>=this.target){
                        this.dialogHabis = true
                        clearInterval(this.interval)
                        this.mapel.elapsedTime = this.$options.filters.two_digits(this.hoursElapsed)+':'+this.$options.filters.two_digits(this.minutesElapsed)+':'+this.$options.filters.two_digits(this.secondsElapsed)
                        // localStorage.setItem('mapel', JSON.stringify(this.mapel))
                        store.commit('updateMapel', this.mapel)
                    }
                },1000);
            }
            this.progress = (this.mapel.soal.filter(x=>x.jawab&&x.jawab!=-1).length/this.mapel.soal_count)*100
            // localStorage.setItem('mapel', JSON.stringify(this.mapel))
            store.commit('updateMapel', this.mapel)
            setTimeout(()=>{
                this.$vuetify.goTo(this.$refs.soal, {
                    duration: 300,
                    offset: 0,
                    easing: 'easeInOutCubic',
                })
            }, 0)
            this.loading = false
        },
    },
    computed: {
        getFontSetting(){
            return "font-family:"+this.mapel.font_family+"; font-size:"+this.mapel.font_size+"px;"
        },
        seconds() {
            return (this.target - this.now) % 60;
        },

        minutes() {
            return Math.trunc((this.target - this.now) / 60) % 60;
        },

        hours() {
            return Math.trunc((this.target - this.now) / 60 / 60) % 24;
        },

        days() {
            return Math.trunc((this.target - this.now) / 60 / 60 / 24);
        },
        secondsElapsed() {
            return (this.now - this.start) % 60;
        },

        minutesElapsed() {
            return Math.trunc((this.now - this.start) / 60) % 60;
        },

        hoursElapsed() {
            return Math.trunc((this.now - this.start) / 60 / 60) % 24;
        },

        daysElapsed() {
            return Math.trunc((this.now - this.start) / 60 / 60 / 24);
        },
        mapel(){
            return store.state.mapel
        },
        target(){
            return store.state.mapel.target
        },
        start(){
            return store.state.mapel.start
        }
    },
    mounted(){
        window.onbeforeunload = function(){
            clearInterval(this.interval)
              /* eslint-disable no-console */
              
          /* eslint-enable no-console */
            // return "Are you sure you want to close the window?";
        }
        let _this = this
        var mql = this.Window.matchMedia("(orientation: portrait)");
        mql.addListener(function(e) {
            if(e.matches){
                _this.portrait = true
                _this.landscape = false
            }else{
                _this.portrait = false
                _this.landscape = true
            }
            /* eslint-disable no-console */
        //   console.log(e.matches);
          /* eslint-enable no-console */
        }, false);
        this.get()
        // if(localStorage.getItem('mapel')){
        if(Object.keys(this.mapel).length > 0){
            // this.mapel = JSON.parse(localStorage.getItem('mapel'))
            this.jawaban = this.mapel.soal[this.model].jawab
            this.toggleRagu = this.mapel.soal[this.model].ragu?0:-1
            this.progress = ((this.model+1)/this.mapel.soal_count)*100
        }
        // this.$set(this.mapel, 'soal', JSON.parse(localStorage.getItem('mapel')).soal)
    },
    beforeDestroy(){
        clearInterval(this.interval)
    },
    watch: {
        model(index){
            if(index==this.mapel.soal_count-1){
                this.next = true
                this.prev = false
            }
            else if(index==0){
                this.prev = true
                this.next = false
            }
            else {
                this.next = false
                this.prev = false
            }
            this.jawaban = this.mapel.soal[index].jawab
            this.toggleRagu = this.mapel.soal[index].ragu?0:-1
            // localStorage.setItem('mapel', JSON.stringify(this.mapel))
        },
    }
  }
</script>

<style scoped>
    .answer-btn{
        border: thin solid rgba(0,0,0,.12) !important;
        /* border-radius: inherit !important; */
    }
    .ragu-btn.v-btn--active{
        background-color: #FEC74E !important;
        color: #FFF !important;
    }
    .flex>.answer-btn.v-btn--active{
        background-color: #6388FA !important;
        color: white !important;
    }
    .layout>.answer-btn.v-btn--active{
        background-color: #6388FA !important;
        color: white !important;
    }
    .answer-field{
        min-width: 9.06px;
    }
    .device-height{
        height: 70vh;
    }
    .device-height-50{
        height: 50vh;
    }
    .device-height-60{
        height: 60vh;
    }
    .v-toolbar--bottom{
        display: flex;
        justify-content: center;
    }
    .boxed-option{
        border: 1px solid black;
        padding: 10px;
        margin: 5px 0px;
    }
    /* .default-font{
        font-family: 'Times New Roman', Times, serif;
    } */
</style>
